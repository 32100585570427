import React from 'react';
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {Helmet} from "react-helmet";


const AvisLegal = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata.title
  return (
    <Layout location={location} tigitle={siteTitle}>
    <Helmet>
      <meta name="robots" content="noindex" />
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.min.js" integrity="sha384-cuYeSxntonz0PPNlHhBs68uyIAVpIIOZZ5JqeqvYYIcEL727kskC66kF92t6Xl2V" crossorigin="anonymous"></script>
    </Helmet>
    <Seo title="Contact us" />
    <article className="global-wrapper blog-post" itemScope itemType="http://schema.org/Article">
    <h1 className="entry-title">Condicions Generals d'ús del lloc web {siteTitle}</h1>
    <section itemprop="articleBody">
    <p>
        
            Aquest avís legal regula l'ús del servei del portal d'Internet firesifestescatalunya.cat, d'ara endavant EL LLOC. 
        <br/>
        
            L'ús del lloc web EL LLOC es regeix per les normes establertes en aquest Avís Legal. 
            Amb la seva utilització, d'ara endavant l'USUARI accepta les condicions d'ús.
        
    </p>
    <h3>
        
            PRIMERA. 
            – CONDICIONS D'ACCÉS I ÚS
        
    </h3>
    <p>
        
            L'USUARI queda informat, i accepta, que l'accés a EL LLOC no suposa, de cap manera, l'inici d'una relació comercial o d'un altre tipus.
        
    </p>
    <p>
        
            La utilització del lloc web EL LLOC no comporta l'obligatorietat d'inscripció de l'USUARI. 
            Les condicions d'accés i ús del present lloc web es regeixen estrictament per la legalitat vigent i pel principi de bona fe i l'USUARI es compromet a fer un bon ús del web. 
            Queden prohibits tots els actes que vulnerin la legalitat, drets o interessos de tercers: dret a la intimitat, protecció de dades, propietat intel·lectual etc.
        
    </p>
    <p>
        
            Expressament EL LLOC prohibeix els següents:
        
    </p>
    <p>
        
            Realitzar accions que puguin produir mitjançant el lloc web oa través del mateix, qualsevol tipus de dany als sistemes d'EL LLOC oa tercers.
        
    </p>
    <p>
        
            Realitzar sense la deguda autorització qualsevol tipus de publicitat o informació comercial directament o de forma encoberta, l'enviament de correus massius (spaming) o enviament de grans missatges per tal de bloquejar servidors de la xarxa
                (mail bombing).
        
    </p>
    <p>
        
            EL LLOC podrà interrompre en qualsevol moment l'accés al vostre lloc web si detecta un ús contrari a la legalitat, la bona fe oa les presents condicions generals. 
            Veure clàusula sisena.
        
    </p>
    <h3>
        
            SEGONA. 
            – CONTINGUTS.
        
    </h3>
    <p>
        
            Els continguts incorporats a aquest lloc web han estat elaborats i inclosos per fonts internes i externes. 
            EL LLOC únicament es fa responsable pels continguts elaborats de forma interna.
        
    </p>
    <p>
        
            Queda reservat el dret d'efectuar sense previ avís les modificacions que consideri oportunes a la seva pàgina web, podent canviar, suprimir o afegir tant els continguts i productes que es prestin a través d'aquesta com la forma en què aquests
                apareguin presentats o localitzats a els seus servidors.
        
    </p>
    <h3>
        
            TERCERA. 
            - DRETS D'AUTOR I MARCA
        
    </h3>
    <p>
        
            Tots els continguts del lloc web EL LLOC (incloent, sense caràcter limitatiu, bases de dades, imatges i fotografies, dibuixos, gràfics i arxius de text) són propietat d'EL LLOC o dels proveïdors de continguts i terceres parts degudament indicades
                i estan protegits per les normes nacionals o internacionals de propietat intel·lectual. 
            La recopilació, disseny, ordenació i muntatge de tot el contingut del lloc web és propietat exclusiva de EL LLOC o dels proveïdors de continguts i terceres parts degudament indicades i es troba protegida per les normes nacionals i internacionals
                de propietat industrial i intel·lectual.
        
    </p>
    <p>
        
            EL LLOC utilitza fonts externes per a l'elaboració dels seus continguts en determinades ocasions i també estableix enllaços a articles o informacions de tercers citant sempre la font. 
            El legítim titular dels drets d'autor d'aquestes informacions així incloses podrà sol·licitar en qualsevol moment l'eliminació de les referències esmentades.
        
    </p>
    <p>
        
            Les marques, rètols, signes distintius o logos del Web referenciat són titularitat de EL LLOC o dels proveïdors de continguts i terceres parts degudament indicades i estan degudament registrats, així com els textos, dades i dibuixos gràfics;
                
            o bé són titularitat de les entitats proveïdores d'informació, i no poden ser objecte d'ulterior modificació, còpia, transformació, alteració, reproducció, adaptació o traducció per part de tercers, sense l'autorització expressa per part del
                titular d'aquest contingut. 
            La posada a disposició dels textos, dades i dibuixos gràfics no implica, en cap cas, la cessió de la titularitat o la concessió d'un dret d'explotació, reproducció, difusió, transformació, distribució o transmissió a favor seu, diferent del
                dret dús que comporta la utilització legítima del Web.
        
    </p>
    <h3>
        
            QUARTA. 
            – JURISDICCIÓ I LLEI APLICABLE
        
    </h3>
    <p>
        
            Aquestes condicions generals es regeixen per la legislació espanyola. 
            Són competents per resoldre tota controvèrsia o conflicte que es derivi de les presents condicions generals els Jutjats de BARCELONA renunciant expressament l'USUARI a qualsevol altre fur que li pogués correspondre.
        
    </p>
    <p>
        
            EL LLOC es compromet al compliment de la seva obligació de secret de les dades de caràcter personal i del seu deure de guardar-les de forma confidencial i adoptarà les mesures necessàries per evitar la seva alteració, pèrdua, tractament o
                accés no autoritzat, tenint en compte en tot moment del estat de la tecnologia.
        
    </p>
    <p>
        
            Així mateix, s'informa l'usuari del seu dret d'accés, rectificació, cancel·lació i, si escau, oposició d'acord amb allò establert a la llei orgànica 15/1999, de 13 de desembre, de protecció de dades de caràcter personal i la resta de normativa
                aplicable a aquest efecte, els quals podrà exercitar mitjançant comunicació remesa a la secció 
        
        <a href="/contacte/">
            
                Contacte
            
        </a>
        
             , amb assumpte “LOPD: Atenció drets d'usuari”.
        
    </p>
    <h3>
        
            CINQUENA. 
            – LIMITACIÓ DE RESPONSABILITAT
        
    </h3>
    <p>
        
            EL LLOC no ofereix garanties de cap classe quant al funcionament del present Web oa la informació continguda en aquest, ni serà responsable dels danys o perjudicis, de qualsevol índole, que puguin derivar-se del seu ús.
        
    </p>
    <p>
        
            Així mateix, EL LLOC exclou tota responsabilitat per la licitud, contingut i qualitat de les dades i informacions oferts per terceres entitats a través de l'esmentat lloc web.
        
    </p>
    <h3>
        
            SISENA. 
            – VALIDESA
        
    </h3>
    <p>
        
            En cas que qualsevol clàusula del present document sigui declarada nul·la, les altres clàusules continuaran vigents i s'interpretaran tenint en compte la voluntat de les parts i la finalitat mateixa de les presents condicions. 
            EL LLOC podrà no exercitar algun dels drets i facultats conferits en aquest document el que no implicarà en cap cas la renúncia als mateixos llevat de reconeixement exprés per part del LLOC.
        
    </p>
    <h3>
        
            SETENA. 
            - PRODUCTES D'AFILIACIÓ
        
    </h3>
    <p>
        
            EL LLOC participa al Programa d'Afiliats d'Amazon EU, un programa de publicitat per a afiliats dissenyat per oferir a llocs web una manera d'obtenir comissions per publicitat, publicitant i incloent enllaços a Amazon.co.uk/ Amazon.de/ de.buyvip
                .com/Amazon.fr/Amazon.it/it.buyvip.com/ Amazon.es/es.buyvip.com.
        
    </p>
    <p>
        
            Els productes analitzats per nosaltres i que apareixen en aquesta pàgina web pertanyen a AMAZON SERVICES LLC. 
            El contingut es mostra directament de la plataforma d'Amazon.es, de manera que si Amazon el canvia sense avís previ, pot canviar el contingut i els preus aquí exposats!
        
        </p>
    </section>
    </article>
    </Layout>
  )
}

export default AvisLegal

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
